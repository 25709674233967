import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Message from 'antd/lib/message';

import { parseQuery } from '@common/typescript/utils/url';
import { getUserName } from '@common/react/utils/utils';
import { getAvatar } from '@common/react/utils/getAvatar';
import { replaceSearchUrl } from '@common/react/components/Utils/Utils';
import {
	Chat,
	ChatMessage,
	ChatMessageActionsComponent,
	ChatMessageType, ChatPlugins,
} from '@common/react/components/Chat/Chat';
import { ApplicationStateWithChats, ChatsActionCreators } from '@common/react/components/Chat/Store/Chats';
import { BaseUserWithAvatar } from '@common/react/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';
import { dateFormat, dateTimeFormat } from '@common/typescript/Utils';
import { File } from '@common/typescript/objects/FileInterface';
import {
	defaultTransformFiltersBeforeHandleUrl,
	ItemsProvider,
	useItemsProviderContext,
} from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import {
	ChatSettingsProviderContext,
	PluginsDictionary,
	useChatSettingsProviderContext,
} from '@common/react/components/Chat/ChatSettingsProvider';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { deleteConfirmation } from '@common/react/components/Modal/Modal';
import { defaultChatMessagesError } from '@common/react/components/Chat/ChatComponents';
import useScrollTo from '@common/react/hooks/useScrollTo';

type ListProps = OwnProps;

type Actions = ChatsActionCreators<BaseUserWithAvatar, ApplicationStateWithChats<BaseUserWithAvatar>>;

interface OwnProps {
	actions: Actions;
	chat: Chat;
	user: BaseUserWithAvatar;
	context: ChatSettingsProviderContext;
	setEdit: (message) => void;
	editMessage?: ChatMessage | null;
	setReplyMessage: (message) => void;
	replyMessage?: ChatMessage | null;
	loader?: React.ReactElement;
	onImageClick?: (e, file: File) => void;
	getUserAvatar?: (user) => React.ReactNode;
}

export interface ChatItemProps {
	message: ChatMessage;
	withRemoteId: boolean;
	fromUser: boolean;
	withoutBadge: boolean;
	user: BaseUserWithAvatar;
	remove: () => void;
	update: (message) => void;
	edit: (message) => void;
	reply: (message) => void;
	isEdit: boolean;
	plugins: PluginsDictionary;
	onMouseEnter?: (message: ChatMessage) => void;
	contacts: Array<BaseUserWithAvatar>;
	onImageClick?: (e, file: File) => void;
	getUserAvatar?: (user) => React.ReactNode;
	lastVideoCallId?: number;
	messageActions?: Array<ChatMessageActionsComponent>;
	showActionsByHover?: boolean;
	scrollIntoView?: boolean;
	afterScroll?: () => void;
	getViewerAvatar?: (messageViewers) => React.ReactNode;
	attachments?: Array<ChatPlugins>;
	attachmentsBefore?: Array<ChatPlugins>;
}

const getMessageUser = (id: number, contacts: Array<BaseUserWithAvatar>) =>
	contacts.find((contact: BaseUserWithAvatar) => contact.id === id);

const allowMessageTypes = [
	ChatMessageType.Regular,
	ChatMessageType.Giphy,
	ChatMessageType.Tenor,
	ChatMessageType.Sticker,
];

const ChatMessageItem: React.FC<ChatItemProps> = ({
	message,
	withoutBadge,
	fromUser,
	user,
	onMouseEnter,
	contacts,
	onImageClick,
	plugins,
	showActionsByHover,
	afterScroll,
	reply,
	attachments,
	attachmentsBefore,
	...rest
}) => {
	const ref = useScrollTo<HTMLLIElement>(rest.scrollIntoView ? 0 : message.id, !rest.scrollIntoView, afterScroll);
	const { withRemoteId, lastVideoCallId } = rest;
	const context = useChatSettingsProviderContext();

	const { state: { onMessageDoubleClick } } = context;

	const className = `chat-message-list-component__item ${
		fromUser ? 'chat-message-list-component__item_you' : ''} ${
		withoutBadge ? 'chat-message-list-component__item_withoutBadge' : ''} ${
		message.viewed ? '' : 'chat-message-list-component__item_unviewed'} ${
		rest.isEdit ? 'chat-message-list-component__item_edit' : ''
	}`;

	const {
		getUserAvatar = (user) => <div
			className="chat-message-list-component__item-avatar"
			style={{ backgroundImage: `url(${getAvatar(user)})` }}
		/>,
		getViewerAvatar = (messageViewer) => <div
			key={`viewer-${messageViewer.id}`}
			style={{
				width: '25px',
				height: '25px',
				borderRadius: '50%',
				overflow: 'hidden',
			}}
		>
			{messageViewer?.user?.avatar
				? <img src={messageViewer?.user.avatar} alt="message-viewer" />
				: <span>{messageViewer?.user?.firstName}</span>}
		</div>,
	} = rest;

	const handleClick = (e) => {
		const ignoreNode = [
			'.chat-file-tag',
			'.message-action',
			'.btn-chat-message-reaction',
			'.chat-message-reactions',
			'.chat-message-list-component__viewers',
			'.ant-tooltip',
			'.ant-popover',
		];

		if (e.detail > 1 && allowMessageTypes.includes(message.chatMessageType)
			&& !ignoreNode.some((node) => e.target?.classList.contains(node) || e.target?.closest(node))) {
			onMessageDoubleClick && onMessageDoubleClick(e, message, reply);
		}
	};

	const messageRender = plugins[message.chatMessageType]
		? plugins[message.chatMessageType]?.message?.render
		: () => null;

	return (message.chatMessageType !== ChatMessageType.Email || withRemoteId)
		? (
			<li
				ref={ref}
				id={`chat-message-${message.id}`}
				className={className}
				onClick={handleClick}
				onMouseEnter={onMouseEnter ? () => onMouseEnter(message) : undefined}
			>
				{
					getViewerAvatar && <div className="chat-message-list-component__viewers">
						{
							message.messageViewers && message.messageViewers
								.filter((messageViewer) => messageViewer.viewed && message.userId !== messageViewer.userId)
								.map((messageViewer) =>
									<div key={`message-viewer-${messageViewer.id}`} style={{ width: '24px', height: '24px', marginLeft: '4px' }}>
										{getViewerAvatar(messageViewer)}
									</div>)
						}
					</div>
				}
				{attachmentsBefore?.map((plugin) => (
					plugins?.[plugin]?.messageAttachmentBefore ? <React.Fragment key={`attachmentBefore${plugin}`}>
						{plugins?.[plugin]?.messageAttachmentBefore?.(message)}
					</React.Fragment> : null))}
				<div className="chat-message-list-component__item_inner">
					{!withoutBadge && user
					&& <>
						{getUserAvatar(user)}
						<div className="chat-message-list-component__item-author">{getUserName(user)}</div>
					</>
					}
					{!withoutBadge && !user && withRemoteId
					&& <div className="chat-message-list-component__item-author">{user ? getUserName(user) : 'DELETED'}</div>}
					<div
						className="chat-message-list-component__item-time"
					>
						{dateFormat(message.time)}
						{' '}
						{dateTimeFormat(message.time)}
					</div>
					{messageRender?.({
						message,
						contacts,
						onImageClick,
						withRemoteId,
						onMouseEnter,
						lastVideoCallId,
					})}
					{attachments?.map((plugin) => (
						plugins?.[plugin]?.messageAttachment ? <React.Fragment key={`attachment${plugin}`}>
							{plugins?.[plugin]?.messageAttachment?.(message)}
						</React.Fragment> : null))}
				</div>
				{
					rest.messageActions?.length
						? (
							<div
								className={`text-end chat-message-list-component__item-actions ${
									showActionsByHover ? 'chat-message-list-component__item-actions-hover' : ''}`}
							>
								{rest.messageActions.map((action) => {
									const props = {
										message,
										edit: rest.edit,
										isEdit: rest.isEdit,
										remove: rest.remove,
										update: rest.update,
										reply,
										fromUser,
										options: typeof action === 'function' ? undefined : plugins[action]?.options,
									};

									if (typeof action === 'function') {
										return action(props);
									}

									return <React.Fragment key={`${action}`}>{plugins[action]?.chatMessageAction?.(props)}</React.Fragment>;
								})}
							</div>
						) : null
				}
			</li>) : null;
};

export const ScrollTo: React.FC<{refreshId: number | string | null, prevent?: boolean}> = ({ refreshId, prevent }) => {
	const ref = useScrollTo<HTMLDivElement>(refreshId, prevent);

	return <div ref={ref} id="chatScrollToBottom" />;
};

const ChatMessageList: React.FC<ListProps & {initLoad?: boolean}> = (props) => {
	const {
		user,
		chat,
		loader,
		actions,
		onImageClick,
		getUserAvatar,
		context: chatSettingsContext,
	} = props;
	const {
		setEdit, editMessage, replyMessage, setReplyMessage,
	} = props;
	const history = useHistory();
	const [scrollToMessageId, setScrollToMessageId] = React.useState(() => {
		const params = parseQuery(history.location.search);
		return +(params.messageId || 0);
	});

	const lastScrollData = React.useRef<{top: number, height: number}>({ top: 0, height: 0 });

	const [scrollId, setScrollId] = React.useState<number | null>(null);
	const [error, setError] = React.useState<string>('');
	const [loadingMore, setLoadingMore] = React.useState<boolean>(false);
	const listRef = React.useRef<HTMLUListElement>(null);

	const context = useItemsProviderContext<ChatMessage>();
	const {
		state: {
			items,
			pagination,
			loading,
			filters,
		},
		actions: {
			load,
			loadMore,
		},
	} = context;

	const {
		state: {
			requests,
			withRemoteId,
			chatStoreSettings: { getMessages },
			messageActions,
			errorHandlers,
			messagesLoadMoreIndicator,
			plugins,
			storageName,
			showActionsByHover,
			avatarSettings,
			request,
		},
	} = chatSettingsContext;

	const messages = useSelector((state: BaseApplicationState<BaseUserWithAvatar>) => {
		return getMessages(chat.id)(state);
	}, shallowEqual);

	const reload = (useResult: {use: boolean} = { use: true }) => {
		setError('');
		const otherChat = chat.contacts.every((contact) => (contact as any).remoteId !== user.id);
		const userId = otherChat ? (chat.contacts as any).find((contact) => contact.remoteId && contact.remoteId > 0)?.remoteId : undefined;

		load({ chatId: chat.id, userId: withRemoteId ? userId || 0 : undefined }, false, false, false, true, useResult)
			.then((res) => {
				res.list.length > 0 && setScrollId(res.list[0].id);
			})
			.catch((e) => {
				setError(e);
			});
	};

	React.useEffect(() => {
		history.listen((location, action) => {
			const messageId = +(parseQuery(location.search).messageId || 0);
			if (messageId) {
				setScrollToMessageId(messageId);
			}
		});
	}, []);

	React.useEffect(() => {
		const useResult = { use: true };
		if ((filters.chatId !== chat.id || props.initLoad) && !messages) {
			reload(useResult);
			return () => {
				useResult.use = false;
			};
		}
		items.length > 0 && setScrollId(items[items.length - 1].id);
		messages && setError('');
	}, [chat.id]);

	React.useEffect(() => {
		if (items.length > 0 && items[items.length - 1].id) {
			setScrollId(items[items.length - 1].id);
		}
	}, [items]);

	const clickHandler = (e) => {
		if (e.target && e.target.nodeName === 'A' && e.target.className === 'chat-component__mention' && e.target.dataset.id) {
			const contact = chat.contacts.find((item) => item.id === +e.target.dataset.id);
			contact && Object.keys(plugins).forEach((plugin) => plugins[plugin]?.options?.onMentionClick?.(contact));
		}
	};

	const setViewed = (message: ChatMessage) => {
		if (!message.viewLoading) {
			actions.updateMessage({ ...message, viewLoading: true }, storageName);
			request(requests.chatMessageAccess, {
				viewed: true,
				message: message.id,
			})
				.then(() => {
					actions.updateMessage({ ...message, viewed: true, viewLoading: false }, storageName);
				})
				.catch((e) => {
					(errorHandlers?.onChatMessageAccessError || Message.error)(e);
					actions.updateMessage({ ...message, viewLoading: false }, storageName);
				});
		}
	};

	const onScroll = (event) => {
		if (!event.target.classList.contains('chat-message-list-component')) return;
		const scrollTop = (event.target as HTMLUListElement).scrollTop;
		if (items.length < pagination.total && !loading && scrollTop < 100 && lastScrollData.current.top - scrollTop > 0) {
			const otherChat = chat.contacts.every((contact: any) => contact.remoteId !== user.id);
			const userId = otherChat ? (chat.contacts as any).find((contact) => contact.remoteId && contact.remoteId > 0)?.remoteId : undefined;

			setLoadingMore((loading) => {
				if (!loading) {
					loadMore({ chatId: chat.id, userId: withRemoteId ? userId || 0 : undefined }, true, true)
						.then(() => {
							if (listRef.current && lastScrollData.current.height > 0) {
								const newHeight = listRef.current.scrollHeight;
								const lastHeight = lastScrollData.current.height;
								let top = newHeight - (lastHeight + lastScrollData.current.top);
								if (top < 0) {
									top = 0;
								}
								listRef.current.scrollTo({ top, left: 0 });
							}
						})
						.catch((e) => {
							(errorHandlers?.onChatMessagesLoadError || Message.error)(e);
						})
						.finally(() => {
							setLoadingMore(false);
						});
				}
				return true;
			});
		}

		lastScrollData.current.top = scrollTop;
		lastScrollData.current.height = listRef.current?.scrollHeight || 0;
	};

	const self = withRemoteId ? chat.contacts.find((contact) => (contact as any).remoteId === user.id) || user : user;
	const contacts = chat.contacts.filter((contact) => (withRemoteId ? (contact as any).remoteId !== self.id : contact.id !== user.id));
	const lastVideoCallId = React.useMemo(() => {
		return items.length > 0 ? [...items]
			.reverse()
			.find((item) => item.chatMessageType === ChatMessageType.VideoChat)?.id : undefined;
	}, [items]);

	const update = (message, params) => {
		requests.updateMessage && request(requests.updateMessage, {
			id: message.id,
			...params,
		})
			.then(() => {
				actions.updateMessage({ id: message.id, chatId: message.chatId, ...params }, storageName);
			});
	};

	const remove = (message) => {
		if (messages && requests.removeMessage) {
			return request(requests.removeMessage, {
				id: message.id,
				deleted: true,
			})
				.then(() => {
					actions.removeMessage(request, requests.getChat, storageName, message, message.chatId);
				});
		}
	};

	const handleRemove = (message) => {
		if (chatSettingsContext.state.removeMessageConfirmation) {
			deleteConfirmation(
				() => remove(message),
				'This message will be permanently deleted and cannot be recovered. Are you sure?',
			);
		} else {
			remove(message);
		}
	};

	const afterScrollToMessage = () => {
		const params = parseQuery(history.location.search);
		if (params.messageId) {
			setScrollToMessageId(0);
			history.replace({
				...history.location,
				search: replaceSearchUrl(history.location.search, 'messageId', ''),
			});
		}
	};

	return <div className="chat-component-list-content" onClick={clickHandler}>
		{loadingMore && messagesLoadMoreIndicator ? <div
			key="load-more-indicator"
			className="chat-message-list-component__load-more-indicator"
		>
			{messagesLoadMoreIndicator}
		</div> : null}
		{error ? (errorHandlers?.chatMessagesErrorComponent || defaultChatMessagesError)({ reload })
			: <>
				{(items && loading) && <div className="chat-message-list-component__loading"><Loader /></div>}
				<ul ref={listRef} className="chat-message-list-component" onScroll={onScroll}>
					{items?.map((item, index) => {
						const withoutBadge: boolean = index > 0 && item.userId === items[index - 1].userId;
						const messageUser = item.userId === self.id
							? { ...user, status: item.user?.status } as BaseUserWithAvatar
							: getMessageUser(item.userId, contacts);

						return <ChatMessageItem
							message={item}
							fromUser={item.userId === self?.id}
							withoutBadge={withoutBadge}
							user={messageUser}
							key={item.id}
							contacts={chat.contacts}
							onMouseEnter={!item.viewed && self && self.id !== item.userId ? setViewed : undefined}
							onImageClick={onImageClick}
							getUserAvatar={getUserAvatar}
							withRemoteId={withRemoteId}
							lastVideoCallId={lastVideoCallId}
							messageActions={messageActions}
							edit={(message) => {
								setEdit(message);
								setReplyMessage(null);
							}}
							attachmentsBefore={chatSettingsContext?.state?.messageAttachmentsBefore}
							attachments={chatSettingsContext?.state?.messageAttachments}
							reply={(message) => {
								setEdit(null);
								setReplyMessage(message);
							}}
							isEdit={item.id === editMessage?.id || item.id === replyMessage?.id}
							remove={() => handleRemove(item)}
							update={(message) => update(item, message)}
							plugins={plugins}
							scrollIntoView={scrollToMessageId === item.id}
							afterScroll={afterScrollToMessage}
							showActionsByHover={showActionsByHover}
							getViewerAvatar={avatarSettings.viewerAvatar}
						/>;
					})}
					<ScrollTo key="scrollTo" refreshId={`${scrollId}-${chat?.id || 0}`} prevent={scrollToMessageId > 0} />
				</ul>
			</>
		}
	</div>;
};

const ChatMessageListWrapper: React.FC<Omit<OwnProps, 'context'>> = (props) => {
	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { requests, storageName, chatStoreSettings: { getMessages } } } = context;

	const messages = useSelector(getMessages(props.chat.id), shallowEqual);

	return <ItemsProvider<ChatMessage>
		items={messages?.list}
		syncItems={messages?.list}
		type={requests.chatMessage}
		transformFiltersBeforeHandleUrl={(filters) => ({
			...defaultTransformFiltersBeforeHandleUrl(filters),
			pageSize: undefined,
		})}
		pagination={{
			total: messages?.count, current: 1, pageSize: 20,
		}}
		filters={{ chatId: props.chat.id }}
		onItemsChange={(items, filters, res) => filters?.chatId && props.actions.setMessages({
			list: items,
			offset: res?.offset ?? items.length - (filters?.pageSize || 20),
			execution: 0,
			count: res?.count ?? items.length,
		}, filters.chatId, storageName)}
	>
		<ChatMessageList
			{...props}
			context={context}
			initLoad={!messages}
		/>
	</ItemsProvider>;
};

export default ChatMessageListWrapper;
