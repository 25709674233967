import {
	TypeKeys as ItemKeys, InitStorageAction as ItemInitStorageAction, InitStorageAction as InitStorageItemAction, TypeKeys as ItemTypeKeys,
} from '@common/react/store/Item';
import { SetItemsAction, TypeKeys } from '@common/react/store/ItemsProviderStore';

import { BaseInvite } from '@commonTuna/react/objects/BaseInvite';

import { Init } from '@app/objects/Init';
import { SetCountersState, CountersTypeKeys } from '@app/store/Counters';
import { SearchFilterTypeKeys, SetSearchFilterState } from '@app/store/SearchFilter';

export const customReduxActions = (dispatch, init: Init) => {
	const buildDataAction: InitStorageItemAction = {
		type: ItemTypeKeys.INITSTORAGE,
		storageName: 'buildData',
		item: {
			buildNumber: init.buildNumber,
			buildDate: init.buildDate,
			maxNameLength: init.maxNameLength,
			...(init as any),
		},
	};

	dispatch(buildDataAction);

	if (init.companyTemplateInvites) {
		const companyTemplateInvitesAction: SetItemsAction<BaseInvite> = {
			type: TypeKeys.SETITEMS,
			storageName: 'companyTemplateInvites',
			items: init.companyTemplateInvites,
			total: init.companyTemplateInvites.length,
			objectType: 'invite',
			params: {
				count: 100,
				isCompanyTemplate: true,
				isAnswered: false,
			},
		};

		dispatch(companyTemplateInvitesAction);
	}

	if (init.userRegistrationSteps) {
		const userRegistrationStepsAction: ItemInitStorageAction = {
			type: ItemKeys.INITSTORAGE,
			storageName: 'userRegistrationSteps',
			item: init.userRegistrationSteps,
		};

		dispatch(userRegistrationStepsAction);
	}

	if (init.counters) {
		const countersAction: SetCountersState = {
			type: CountersTypeKeys.SET_COUNTERS_STATE,
			payload: init.counters,
		};

		dispatch(countersAction);
	}

	if (init.searchFilterData) {
		const searchFilterAction: SetSearchFilterState = {
			type: SearchFilterTypeKeys.SET_SEARCH_FILTER_STATE,
			payload: init.searchFilterData,
		};

		dispatch(searchFilterAction);
	}

	if (init.hostOptions) {
		const hostOptionsAction: InitStorageItemAction = {
			type: ItemTypeKeys.INITSTORAGE,
			storageName: 'hostOptions',
			item: init.hostOptions,
		};

		dispatch(hostOptionsAction);
	}
};
