import React from 'react';

import useFlashingTitle from '@common/react/hooks/useFlashingTitle';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';

const MessageFlashingTime: React.FC = () => {
	const {
		getUser,
	} = useApplicationContext();
	const user = getUser();
	const notViewMC = user?.unviewedMessagesCount || 0;

	useFlashingTitle({
		title: user && (notViewMC > 0)
			? `${notViewMC > 0 ? `+${notViewMC} ✉` : ''}` : undefined,
	});

	return <></>;
};

export default MessageFlashingTime;
