import React from 'react';

import Select from 'antd/lib/select';

import Autocomplete, { OptionType } from '@common/react/components/Forms/Autocomplete/Autocomplete';
import { WithId } from '@common/typescript/objects/WithId';
import { Nullable } from '@common/typescript/objects/Nullable';

import { DoctorSpecialty } from '@commonTuna/react/objects/DoctorSpecialty';
import { GlobalProcedure } from '@commonTuna/react/objects/GlobalProcedure';
import ImageWithSkeleton from '@commonTuna/react/components/UI/ImageWithSkeleton/ImageWithSkeleton';

import { Doctor } from '@app/objects/Doctor';

interface SearchResultRow extends WithId {
	doctor: Nullable<Doctor>;
	specialties: Nullable<DoctorSpecialty>;
	globalProcedure: Nullable<GlobalProcedure>;
}

interface Props {
	value: string;
	onSelect: (value, option) => void;
	onChange: (value) => void;
	initType?: SearchType;
	onSearchClick?: (e, value) => void;
	onCloseClick?: (e, value, setValue) => void;
	initFocus?: boolean;
}

interface Options {
	label: React.ReactNode;
	options: Array<OptionType>;
}

export const emptyValues = {
	doctorId: undefined,
	globalProcedureId: undefined,
	specialtyId: undefined,
	globalPayerId: undefined,
	specialId: undefined,
	locationId: undefined,
	professionId: undefined,
};

export const clearValues = Object.keys(emptyValues)
	.map((key) => ({ name: key, value: undefined } as any))
	.concat([{ name: 'text', value: '' }]);

const { Option } = Select;

enum SearchType {
	All = 0,
	Doctor = 1,
	Procedure = 2,
	Speciality = 3,
	Clinic = 4,
	Insurance = 5,
	Special = 6,
	Location = 7
}

const searchOptions = [
	{ name: 'All', value: SearchType.All },
	{ name: 'Doctors', value: SearchType.Doctor },
	{ name: 'Procedures', value: SearchType.Procedure },
	{ name: 'Specialities', value: SearchType.Speciality },
	{ name: 'Clinics', value: SearchType.Clinic },
	{ name: 'Insurances', value: SearchType.Insurance },
	{ name: 'Specials', value: SearchType.Special },
	{ name: 'Locations', value: SearchType.Location },
];

const sections = ['doctor', 'globalProcedure', 'specialty', 'insurance', 'clinic', 'profession', 'special', 'location'];

const Search: React.FC<Props> = ({
	value, onChange, onSelect, initType, onSearchClick, initFocus, onCloseClick,
}) => {
	const [type, setType] = React.useState<SearchType>(initType || SearchType.All);
	const ref = React.useRef<any>(null);
	const [currentValue, setCurrentValue] = React.useState(value);

	React.useEffect(() => {
		initFocus && ref.current?.focus();
	}, [initFocus]);

	React.useEffect(() => {
		if (value !== currentValue) {
			setCurrentValue(value);
		}
	}, [value]);

	const handleChange = (value) => {
		setCurrentValue(value);
	};

	const renderTitle = (title, icon) => <>
		{icon}
		{' '}
		{title}
	</>;

	const renderItem = (value: React.ReactNode, label: React.ReactNode, option: OptionType): OptionType => {
		return {
			value,
			label,
			item: option.item,
			key: option.item.id,
		};
	};

	const getSection = (section: string, filteredOptions: Array<OptionType>): Options => {
		if (section === 'doctor') {
			return {
				label: renderTitle('Doctors', <i className="fa fa-user-md mr10" aria-hidden="true" />),
				options: filteredOptions.map((option) => renderItem(`Doctor: ${option.item.doctor.nameEn}`, option.item.doctor.nameEn, option)),
			};
		}
		if (section === 'globalProcedure') {
			return {
				label: renderTitle(
					'Procedures',
					<svg
						aria-hidden="true"
						focusable="false"
						data-prefix="fas"
						data-icon="procedures"
						role="img"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 640 512"
						className="fa-procedures mr10"
					>
						<path
							fill="currentColor"
							// eslint-disable-next-line
							d="M528 224H272c-8.8 0-16 7.2-16 16v144H64V144c0-8.8-7.2-16-16-16H16c-8.8 0-16 7.2-16 16v352c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48h512v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V336c0-61.9-50.1-112-112-112zM136 96h126.1l27.6 55.2c5.9 11.8 22.7 11.8 28.6 0L368 51.8 390.1 96H512c8.8 0 16-7.2 16-16s-7.2-16-16-16H409.9L382.3 8.8C376.4-3 359.6-3 353.7 8.8L304 108.2l-19.9-39.8c-1.4-2.7-4.1-4.4-7.2-4.4H136c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm24 256c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64z"
						/>
					</svg>,
				),
				options: filteredOptions.map((option) => renderItem(
					`Procedure: ${option.item.globalProcedure.name}`,
					option.item.globalProcedure.name,
					option,
				)),
			};
		}
		if (section === 'specialty') {
			return {
				label: renderTitle('Specialties', <i className="fa fa-briefcase mr10" aria-hidden="true" />),
				options: filteredOptions.map((option) => renderItem(`Specialty: ${option.item.specialty.name}`, option.item.specialty.name, option)),
			};
		}
		if (section === 'insurance') {
			return {
				label: renderTitle('Insurances', <i className="fa fa-plus mr10" aria-hidden="true" />),
				options: filteredOptions.map((option) => renderItem(
					`Insurance: ${option.item.insurance.name}`,
					<>
						{option.item.insurance.avatar
							&& <div className="option-img__container">
								<ImageWithSkeleton className="option-img" src={`/remote/${option.item.insurance.avatar}`} />
							</div>
						}
						{option.item.insurance.name}
					</>,
					option,
				)),
			};
		}
		if (section === 'clinic') {
			return {
				label: renderTitle('Clinics', <i className="fa fa-hospital-o mr10" aria-hidden="true" />),
				options: filteredOptions.map((option) =>
					renderItem(
						`Clinic: ${option.item.clinic.company?.name} - ${option.item.clinic.nameEn}`,
						`${option.item.clinic.company?.name} - ${option.item.clinic.nameEn}`,
						option,
					)),
			};
		}
		if (section === 'profession') {
			return {
				label: renderTitle('Professions', <i className="fa fa-user mr10" aria-hidden="true" />),
				options: filteredOptions
					.map((option) => renderItem(
						`Profession: ${option.item.profession.nameEn}`,
						option.item.profession.nameEn,
						option,
					)),
			};
		}
		if (section === 'special') {
			return {
				label: renderTitle('Specials', <i className="fa fa-star mr10" aria-hidden="true" />),
				options: filteredOptions.map((option) => renderItem(
					`Special: ${option.item.special.name}`,
					<>
						{option.item.special.avatar
							? <div className="option-img__container">
								<ImageWithSkeleton className="option-img" src={`/remote/${option.item.special.avatar}`} />
							</div> : null}
						{option.item.special.name}
					</>,
					option,
				)),
			};
		}
		if (section === 'location') {
			return {
				label: renderTitle('Locations', <i className="fa fa-map mr10" aria-hidden="true" />),
				options: filteredOptions.map((option) => renderItem(`Location: ${option.item.location.name}`, option.item.location.name, option)),
			};
		}
		return {
			label: '',
			options: [{
				value: '', label: '', item: '', key: -1,
			}],
		};
	};

	const getOptions = (options: Array<OptionType>): Array<Options> => {
		const result: Array<Options> = [];
		for (let i = 0; i < sections.length; i++) {
			const section = sections[i];
			const filteredOptions = options.filter((o) => o.item[section] !== null);
			if (filteredOptions.length > 0) {
				result.push(getSection(section, filteredOptions));
			}
		}

		return result;
	};

	return <>
		<Select
			style={{ width: 120 }}
			value={type}
			onSelect={(value) => {
				setType(value);
				setTimeout(() => {
					if (ref.current) {
						ref.current.focus();
					}
				}, 0);
			}}
		>
			{searchOptions.map((item) => <Option value={item.value} key={item.value}>
				{item.name}
			</Option>)}
		</Select>
		<Autocomplete<SearchResultRow>
			autocompleteRef={ref}
			type="search"
			loadOnFocus
			getOptions={getOptions}
			value={currentValue}
			onSelect={onSelect}
			onChange={handleChange}
			antdProps={{
				style: { width: '100%' },
				defaultActiveFirstOption: false,
				placeholder: 'Start type for search...',
				onKeyDown: (event) => {
					if (event.key === 'Enter') {
						onChange && onChange(currentValue);
					}
				},
			}}
			params={{ count: 5, searchType: type }}
		/>
		<i className="fa fa-search" onClick={(e) => onSearchClick?.(e, currentValue)} />
		<i className="fa fa-times" onClick={(e) => onCloseClick?.(e, currentValue, setCurrentValue)} />
	</>;
};

export default Search;
