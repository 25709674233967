import * as React from 'react';

import { bindActionCreators } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Message from 'antd/lib/message';

import { ChatsActionCreators, ApplicationStateWithChats } from '@common/react/components/Chat/Store/Chats';
import { BaseUserWithAvatar } from '@common/react/objects/BaseUser';
import { List } from '@common/typescript/objects/List';
import ChatMessageList from '@common/react/components/Chat/ChatMessageList';
import ChatMessageForm from '@common/react/components/Chat/ChatMessageForm';
import { ChatItem } from '@common/react/components/Chat/ChatListItem';
import { deleteConfirmation } from '@common/react/components/Modal/Modal';
import { Chat, ChatMessage } from '@common/react/components/Chat/Chat';
import { BaseApplicationState } from '@common/react/store';
import { parseQuery } from '@common/typescript/utils/url';
import { ItemsProvider, useItemsProviderContext } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import '@common/react/scss/components/chats.scss';
import {
	ChatSettingsProviderContext,
	useChatSettingsProviderContext,
} from '@common/react/components/Chat/ChatSettingsProvider';

import useServerEffect from '@common/react/hooks/useServerEffect';
import Button from '@common/react/components/Forms/Button';
import { File as FileInterface } from '@common/typescript/objects/FileInterface';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';

interface OwnProps {
	actions: Actions;
	initLoad: boolean;
	preventRedirectToChat?: boolean;
	context: ChatSettingsProviderContext;
}

type Actions = ChatsActionCreators<BaseUserWithAvatar, ApplicationStateWithChats<BaseUserWithAvatar>>;

export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'heic', 'heif'];

const Chats: React.FC<OwnProps> = (props) => {
	const { actions, context: chatSettingsContext, preventRedirectToChat } = props;
	const history = useHistory();
	const {
		state: {
			requests,
			pageSettings,
			chatStoreSettings,
			chatListHeaderSettings,
			otherComponents,
			emptyChatListMessage,
			basePath,
			plugins,
			storageName,
			getUser,
			request,
		},
		actions: { setChatsFilters },
	} = chatSettingsContext;
	const {
		state: {
			avatarSettings, messagesHeaderComponents, renderChatName, withRemoteId, modalMode, openInModal, reloadChatId,
		},
		actions: {
			setReloadChatId,
		},
	} = chatSettingsContext;
	const user = getUser();

	const { currentChat } = useSelector((state: BaseApplicationState<BaseUserWithAvatar>) => ({
		currentChat: chatStoreSettings.getCurrentChat(state),
	}), shallowEqual);
	const messages = useSelector(chatStoreSettings.getMessages(currentChat?.id), shallowEqual);

	const context = useItemsProviderContext<Chat>();
	const {
		state: {
			items, loading, pagination, filters,
		}, actions: { load, handleChange, loadMore },
	} = context;

	const patientId = React.useRef<number | null>(null);
	const [state, setState] = React.useState({ completeChatLoading: false });
	const [editMessage, setEdit] = React.useState<ChatMessage | null>(null);
	const [replyMessage, setReplyMessage] = React.useState<ChatMessage | null>(null);

	const [img, setImg] = React.useState<FileInterface | undefined>();
	const images = React.useMemo(() => {
		const files: Array<FileInterface> = [];
		[...(messages?.list || [])].reverse().forEach((message) => {
			[...message.files].reverse().forEach((f) => (imageExtensions.includes(f.file.extension?.toLowerCase()) ? files.push(f) : ''));
		});
		return files;
	}, [messages]);
	const imageIndex = React.useMemo(() => {
		const index = images.findIndex((image) => image.file.id === img?.file.id);
		return index < 0 ? null : index;
	}, [images, img]);

	const emptyMessage = React.useMemo(() => {
		return typeof emptyChatListMessage === 'function' ? emptyChatListMessage({ filters, load }) : emptyChatListMessage;
	}, [filters, emptyChatListMessage, load]);

	const chatsListHandleChange = (e) => {
		setTimeout(() => {
			if (e?.currentTarget) {
				setChatsFilters({ [e?.currentTarget.name]: e?.currentTarget.value });
			} else {
				setChatsFilters(e);
			}
		}, 0);
		return handleChange(e);
	};

	const selectChat = (chat: Chat | null) => {
		const search = parseQuery(history.location.search);
		const archive = search[pageSettings.archive] || false;

		if (!(modalMode || openInModal || preventRedirectToChat) || pageSettings.path === history.location.pathname) {
			if (chat) {
				history.replace({
					pathname: pageSettings.path,
					search: `?${pageSettings.chatIdUrlKey}=${chat.id}&${pageSettings.archive}=${archive}`,
				});
			} else {
				history.replace({
					pathname: pageSettings.path,
					search: '',
				});
			}
		}
		setReplyMessage(null);
		actions.selectChat(chat, storageName);
	};

	const leaveChat = () => {
		deleteConfirmation(() => {
			if (currentChat && user) {
				request(requests.chatUser, {
					chatId: currentChat.id,
					userId: user.id,
					deleted: true,
				})
					.then(() => {
						handleChange()
							.then((res) => {
								const chat = res?.list.filter((chat) => chat.id !== currentChat?.id)?.[0];
								selectChat(chat || null);
							});
					});
			}
		});
	};

	const completeChat = (chat: Chat) => {
		setState((prevState) => ({
			...prevState,
			completeChatLoading: true,
		}));
		if (!requests.completeChat) return;

		return request(requests.completeChat, {
			id: chat.id,
			archive: !chat.archive,
		})
			.then((res) => {
				handleChange();
			})
			.catch((e) => {
				Message.error(e);
			})
			.finally(() => {
				setState((prevState) => ({
					...prevState,
					completeChatLoading: false,
				}));
			});
	};

	useServerEffect(() => {
		const search = parseQuery(history.location.search);
		const chatId = +search[pageSettings.chatIdUrlKey] || null;
		const archive = search[pageSettings.archive] === 'true';

		actions.loadChats(request, requests.loadChats, storageName, (data: List<Chat>) => {
			if (data.list.length > 0) {
				if (chatId && chatId > 0) {
					actions.selectChat(data.list.find((chat) => chat.id === chatId), storageName);
					actions.loadMessages(request, requests.loadMessages, storageName, chatId, false);
				} else {
					actions.selectChat(data.list[0], storageName);
					data.list[0] && actions.loadMessages(request, requests.loadMessages, storageName, data.list[0]?.id, false);
				}
			}
		}, { ...filters, archive, chatId });
	});

	const onLoad = (chatId, data) => {
		if (data.list.length > 0) {
			if (chatId && chatId > 0 && data.list[0].id === chatId) {
				actions.selectChat(data.list.find((chat) => chat.id === chatId), storageName);
				setChatsFilters({ archive: data.list[0].archive });
			} else {
				actions.selectChat(data.list[0], storageName);
			}

			if (!(modalMode || openInModal || preventRedirectToChat) || pageSettings.path === history.location.pathname) {
				history.replace({
					pathname: pageSettings.path,
					search: `?${pageSettings.chatIdUrlKey}=${data.list[0].id}&${pageSettings.archive}=${data.list[0].archive}`,
				});
			}
		}
	};

	React.useEffect(() => {
		if (props.initLoad) {
			const search = parseQuery(history.location.search);
			const chatId = reloadChatId || +search[pageSettings.chatIdUrlKey] || null;
			const archive = search[pageSettings.archive] === 'true';

			load({ chatId, archive }).then((data: List<Chat>) => {
				onLoad(chatId, data);
			});
		}
	}, []);

	React.useEffect(() => {
		if (reloadChatId && !props.initLoad) {
			const search = parseQuery(history.location.search);
			const chatId = reloadChatId;
			const archive = search[pageSettings.archive] === 'true';

			load({ chatId, archive }).then((data: List<Chat>) => {
				onLoad(chatId, data);
			});
		}
		setReloadChatId(undefined);
	}, [reloadChatId]);

	React.useEffect(() => {
		setEdit(null);
	}, [currentChat?.id]);

	const handleIconClick = (e, file: FileInterface) => {
		e.preventDefault();
		setImg(file);
	};

	if (user) {
		const userId = user.id;
		const showForm = !withRemoteId || !filters.loadAllChats || currentChat?.contacts.some((user) => (user as any).remoteId === userId);
		const showMessagesHeader = !!renderChatName || !!messagesHeaderComponents.length;

		return <div className="chat-component">
			<div className="chat-component__left">
				<React.Fragment key="chats-filters">
					{
						!!chatListHeaderSettings.length && <div className="chat-buttons">
							{chatListHeaderSettings?.map((item, i) => ({ item, i })).map(({ item, i }) =>
								<React.Fragment key={`${typeof item === 'function' ? '' : item}${i}`}>
									{typeof item === 'function'
										? item({
											handleChange,
											filters,
											user,
											selectChat,
											pageSettings,
										})
										: plugins[item]?.chatsListHeaderComponent?.({
											user,
											filters,
											selectChat,
											handleChange: chatsListHandleChange,
											pageSettings,
										})}
								</React.Fragment>)
							}
						</div>
					}
				</React.Fragment>
				<React.Fragment key="chat-list">
					{items.length > 0
						? <ul className="chat-list is-relative">
							{items.map((item, index) =>
								<ChatItem
									getUser={avatarSettings.getChatAvatar}
									chat={item}
									userId={userId}
									key={item.id}
									selectedChatId={currentChat ? currentChat.id : null}
									onSelect={selectChat}
								/>)
							}
							<React.Fragment key="load more">
								{items.length < pagination.total ? <ul className="text-center chat-list__item">
									<Button isLoading={loading} onClick={() => loadMore(filters)}>
										Load more
									</Button>
								</ul> : loading && <div className="chat-component__left-loader">
									<Loader />
								</div>}
							</React.Fragment>
						</ul>
						: <div className="chat-component__not-found is-relative">
							{loading ? <div className="chat-component__left-loader">
								<Loader />
							</div> : emptyMessage}
						</div>
					}
				</React.Fragment>
			</div>
			<React.Fragment key="colorbox">
				{otherComponents?.map((plugin) => {
					const props = {
						chat: currentChat, defaultIdx: imageIndex, setImg, files: images, basePath,
					};
					const component = typeof plugin === 'function' ? plugin(props)
						: plugins[plugin]?.additionalComponent?.({
							chat: currentChat, defaultIdx: imageIndex, setImg, files: images, basePath,
						});

					return <React.Fragment key={`${plugin}`}>
						{component}
					</React.Fragment>;
				})}
			</React.Fragment>
			<div className={`chat-component__right ${showForm ? 'chat-component__right__with-form' : ''}`}>
				{currentChat
					? <>
						{showMessagesHeader && <div className="chat-component__headline clearfix">
							<React.Fragment key="chat name">
								{renderChatName && <div className="chat-component__headline__name">
									{renderChatName({
										currentChat, user, withRemoteId, patientId,
									})}
								</div>}
							</React.Fragment>
							{
								messagesHeaderComponents?.map((item, i) => ({ item, i })).map(({ item, i }) => {
									const props = {
										storageName,
										leaveChat,
										currentChat,
										user,
										history,
										patientId,
										state,
										completeChat,
										withRemoteId,
										actions,
									};
									return <React.Fragment key={`${typeof item !== 'function' ? item : ''} ${i}`}>
										{
											typeof item !== 'function'
												? plugins[item]?.messagesHeaderAction?.(props)
												: item(props)
										}
									</React.Fragment>;
								})
							}
						</div>}
						<ChatMessageList
							onImageClick={handleIconClick}
							actions={actions}
							chat={currentChat}
							user={user}
							replyMessage={replyMessage}
							setReplyMessage={setReplyMessage}
							getUserAvatar={avatarSettings.getUserAvatar}
							editMessage={editMessage}
							setEdit={setEdit}
						/>
						{showForm ? <ChatMessageForm
							setEdit={setEdit}
							setReply={setReplyMessage}
							replyMessage={replyMessage}
							editMessage={editMessage}
							getUserAvatar={avatarSettings.getUserAvatarAtMention}
							chat={currentChat}
						/> : null}
					</>
					: null
				}
			</div>
		</div>;
	}

	return null;
};

const ChatsWrapper: React.FC<Omit<OwnProps, 'context' | 'actions' | 'initLoad'>> = (props) => {
	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const {
		state: {
			requests, pageSettings, chatStoreSettings, title, chatsFilters, errorHandlers, storageName, getUser,
		},
	} = context;
	const { getChatStore, getActionCreators } = chatStoreSettings;

	const history = useHistory();
	const search = parseQuery(history.location.search);
	const chatId = +search[pageSettings.chatIdUrlKey] || null;

	const dispatch = useDispatch();
	const actions: Actions = React.useMemo(() => bindActionCreators(getActionCreators(), dispatch), []);

	const { chats, chatsLoaded } = useSelector(getChatStore, shallowEqual);
	const user = getUser();

	if (!user) {
		return null;
	}

	return (
		<div className="chats-page">
			{title && <div className="site-headline site-headline_with-button clearfix">
				<h1 className="pull-left">{title}</h1>
			</div>}
			<ItemsProvider<Chat>
				items={chats.list}
				syncItems={chats.list}
				pagination={{ total: chats?.count, current: 1, pageSize: 10 }}
				type={requests.chat}
				filters={{
					chatId,
					...chatsFilters,
				}}
				transformItems={(items) => items.map((item) => ({
					...item,
					unviewedMessagesCount: item.unviewedMessagesCount > 0 ? item.unviewedMessagesCount : 0,
				}))}
				onItemsChange={(items, filters, res) => actions.setChats({
					list: items,
					offset: res?.offset ?? items.length - (filters?.pageSize || 10),
					execution: res?.offset ?? 0,
					count: res?.count ?? items.length,
				}, storageName)}
				onRequestError={(e) => {
					(errorHandlers?.onChatsLoadError || Message.error)(e);
				}}
			>
				<Chats context={context} {...props} actions={actions} initLoad={!chatsLoaded} />
			</ItemsProvider>
		</div>
	);
};

export default ChatsWrapper;
