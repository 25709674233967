export const dateToLocaleCustom = (t?: number):string => {
	if (!t) return '';

	const date = new Date(t);
	const year = date.getFullYear();
	const month = (`0${date.getMonth() + 1}`).slice(-2);
	const day = (`0${date.getDate()}`).slice(-2);

	return `${month}/${day}/${year}`;
};

export const dateTimeToLocaleCustom = (t?: number):string => {
	if (!t) return '';

	return `${dateToLocaleCustom(t)} ${new Date(t).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' })}`;
};

export const dateToStringCustom = (t?: number):string => {
	if (!t) return '';

	return dateToLocaleCustom(t + (new Date(t)).getTimezoneOffset() * 60 * 1000);
};

export const dateTimeToStringCustom = (t?: number):string => {
	if (!t) return '';

	return dateTimeToLocaleCustom(t + (new Date(t)).getTimezoneOffset() * 60 * 1000);
};

export const enumNameToTitle = (name: string):string => {
	return name.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
};

export const getPopupContainer = (triggerNode) => triggerNode.parentNode as HTMLElement;

export const replaceSearchUrl = (search: string, key: string, value: string, removeEmpty = true): string => {
	const remove = removeEmpty && ['', 'null', 'undefined', '-1', '[]'].indexOf(value) !== -1;

	if (search.indexOf(`${key}=`) !== -1) {
		return search.replace(new RegExp(`(&)*(${key}=)[^\\&]*(&)*`), (a, b, c, d) => {
			return remove ? (b && d ? '&' : '') : `${(b || '')}${key}=${value}${(d || '')}`;
		});
	}

	return `${
		search ? `${search}` : ''}${
		remove ? '' : `${(search.indexOf('?') === -1 ? '?' : (search.slice(-1) === '?' ? '' : '&'))}${key}=${value}`}`;
};
