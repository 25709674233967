import * as React from 'react';

import Mentions from 'antd/lib/mentions';
import Tag from 'antd/lib/tag';
import {
	FieldProps, FormikProps,
} from 'formik';
import * as Yup from 'yup';
import message from 'antd/lib/message';
import Popover from 'antd/lib/popover';

import {
	Chat,
	ChatMessage,
	ChatMessageType,
	NewMessage,
} from '@common/react/components/Chat/Chat';
import Button from '@common/react/components/Forms/Button';
import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import { getUserName } from '@common/react/utils/utils';
import { BaseUser, BaseUserWithAvatar } from '@common/react/objects/BaseUser';
import { FileInterface, FileType } from '@common/typescript/objects/FileInterface';
import FormikRef from '@common/react/components/Core/ItemEditor/FormikRef';
import { RecordResult } from '@common/react/utils/record-audio';
import { uploadFile } from '@common/react/components/Forms/Files/File';
import {
	useChatSettingsProviderContext,
} from '@common/react/components/Chat/ChatSettingsProvider';
import { imageExtensions } from '@common/react/components/Chat/Chats';
import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { ItemEditor } from '@common/react/components/Core/ItemEditor/ItemEditor';

export interface ButtonsProps {
	onUploadFile: (file: FileInterface<BaseUser>) => void;
}

interface ComponentProps {
	chat: Chat;
	editMessage: ChatMessage | null;
	setEdit: (message) => void;
	afterSubmit?: (message) => void;
	sendClassName?: string;
	onImageClick?: (e, file: FileInterface<BaseUser>) => void;
	actionsInPopup?: boolean;
	filesAfterButtons?: boolean;
	getActionPopupContainer?: (node) => HTMLElement;
	getUserAvatar?: (user) => React.ReactNode;
	replyMessage?: ChatMessage | null;
	setReply?: (message) => void;
}

interface ContactsHash {
	[index: number]: string;
}

export interface ChatFormComponentState {
	isLoading: boolean;
	contacts: ContactsHash;

	recordAudioResult: RecordResult | undefined;
	recordVideoResult: RecordResult | undefined;
}

const getValidationSchema = (maxLength: number) => Yup.object().shape({
	text: Yup.string().max(maxLength, `max characters count: ${maxLength}`),
});

const transformContactsToHash = (contacts: Array<BaseUserWithAvatar>) => contacts.reduce((map, obj) => {
	map[obj.id] = getUserName(obj);
	return map;
}, {});

interface FilesProps {
	formikBag: FormikProps<NewMessage>;
	onRemoveUploadedFile: (id, formikBag) => void;
	state: ChatFormComponentState;
	setState: React.Dispatch<React.SetStateAction<ChatFormComponentState>>;
	onImageClick?: (e, f) => void;
}

const Files: React.FC<FilesProps> = ({
	formikBag, onRemoveUploadedFile, onImageClick, state, setState,
}) => {
	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const {
		state: {
			formSettings,
			formTags,
			plugins,
		},
	} = context;
	const { waveColor } = formSettings;

	return <div className="chat-uploaded-files" style={{ flexWrap: 'wrap' }}>
		{formikBag.values.files
			.map((f) => (
				<Tag
					className="chat-file-tag"
					key={f.id}
					title={f.name}
					onClose={() => onRemoveUploadedFile(f.id, formikBag)}
					closable
					closeIcon={<i className="fa fa-times" />}
				>
					{imageExtensions.includes(f.extension?.toLowerCase())
						? (
							<img
								onClick={(e) => onImageClick && onImageClick(e, f)}
								className="chat-form-file-tag-image"
								src={f.thumb}
								alt={f.name}
							/>
						)
						: <a href={f.src} target="_blank" download rel="noreferrer"><i className="fa fa-download" /></a>
					}
					&nbsp;&nbsp;
					<span className="chat-file-tag_name">{f.name}</span>
&nbsp;&nbsp;
				</Tag>
			))
		}
		<React.Fragment key="additionalFiles">
			{formTags.map((plugin) => <React.Fragment key={`${plugin}`}>
				{plugins[plugin]?.formTag?.(formikBag, { state, setState, waveColor })}
			</React.Fragment>)}
		</React.Fragment>
	</div>;
};

const ChatMessageForm: React.FC<ComponentProps> = (props) => {
	const {
		sendClassName = 'btn-primary', chat, onImageClick, editMessage, setEdit, actionsInPopup, filesAfterButtons,
	} = props;
	const {
		getActionPopupContainer, replyMessage, setReply, getUserAvatar,
	} = props;
	const form = React.useRef<FormikProps<NewMessage> | null>(null);

	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const {
		state: {
			formButtons,
			chatFormButtonsWrappers,
			requests,
			formSettings,
			errorHandlers,
			plugins,
			messageControl,
			messageControlWrappers,
			sendButtonWrapper,
		},
	} = context;
	const { maxAttachmentsCount, maxMessageLength, waveColor } = formSettings;

	const [state, _setState] = React.useState<ChatFormComponentState>({
		isLoading: false,
		contacts: props.chat ? transformContactsToHash(props.chat.contacts) : {},
		recordAudioResult: undefined,
		recordVideoResult: undefined,
	});
	const setState = React.useCallback((newState) => _setState((state) => ({ ...state, ...newState })), []);
	const buttonWrapper = sendButtonWrapper && plugins[sendButtonWrapper]?.sendButtonWrapper;

	React.useEffect(() => {
		setState({
			contacts: props.chat ? transformContactsToHash(props.chat.contacts) : {},
		});
	}, [props.chat]);

	const onUploadFile = (file: FileInterface<BaseUser>, formikBag: FormikProps<NewMessage>) => {
		formikBag.setValues((prev) => {
			const nextFiles = formikBag.values.files.concat(file);

			if (nextFiles.length > maxAttachmentsCount) {
				message.info(`Max Attachment files count: ${maxAttachmentsCount}`);
				return prev;
			}

			return {
				...prev,
				files: prev.files.concat(file),
			};
		});
	};

	const onRemoveUploadedFile = (id: number, formikBag) => {
		formikBag.setValues((prev) => {
			return {
				...prev,
				files: prev.files.filter((f) => f.id !== id),
			};
		});
	};

	const onKeyDown = (e: KeyboardEvent) => {
		const formikBag = form.current;

		if (formikBag && e.ctrlKey && e.code === 'Enter') {
			e.preventDefault();
			formikBag.submitForm();
		}
	};

	const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>, formikBag: FormikProps<NewMessage>) => {
		const items = Array.from(e.clipboardData.items).filter((x) => /^image\//.test(x.type));

		items.forEach((item) => {
			const blob = item?.getAsFile();

			uploadFile({
				file: blob,
				type: 'chatMessage',
				objectId: -1,
				fileType: FileType.File,
			})
				.then((data) => onUploadFile(data, formikBag))
				.catch((err: any) => {
					message.error(typeof err === 'object' ? err.message : err);
				});
		});
	};

	React.useEffect(() => {
		document.addEventListener('keydown', onKeyDown);
		return () => document.removeEventListener('keydown', onKeyDown);
	}, []);

	const MentionComponent = React.useMemo(() => {
		return ({ placement, ...props }: any) => {
			const mention = (onKeyDown) => {
				const handleKeyDown = (e) => {
					onKeyDown && onKeyDown(e);
					props.onKeyDown && props.onKeyDown(e);
				};
				return messageControl && plugins[messageControl]?.formComponent
					? plugins[messageControl]?.formComponent?.({ placement, ...props, onKeyDown: handleKeyDown })
					: <Mentions {...props} onKeyDown={handleKeyDown} />;
			};
			if (!messageControlWrappers || messageControlWrappers.some((plugin) => !plugins[plugin]?.messageControlWrapper)) {
				return <>{mention(undefined)}</>;
			}

			return <>
				{(messageControlWrappers?.reduce(
					(acc, plugin) =>
						(handleKeyDown) => {
							return <>
								{plugins[plugin]?.messageControlWrapper?.({
									chat,
									render: (onKeyDown) => {
										const keyDownHandler = (e) => {
											onKeyDown && onKeyDown(e);
											handleKeyDown && handleKeyDown(e);
										};
										return acc(keyDownHandler);
									},
								})}
							</>;
						},
					mention,
				) || mention)?.(undefined)}
			</>;
		};
	}, [messageControl, `${messageControlWrappers}`, chat.id]);

	const validationSchema = React.useMemo(() => {
		return getValidationSchema(maxMessageLength);
	}, []);

	const mentionOptions = React.useMemo(() => {
		return chat.contacts.map((item) => {
			const name = getUserName(item);

			return {
				value: name,
				label: <>
					{getUserAvatar && <div className="user-avatar-mention">{getUserAvatar(item)}</div>}
					<span className="mention-text">{name}</span>
				</>,
			};
		});
	}, [chat.contacts, messageControl && plugins[messageControl]?.formComponent]);

	const getActions = (formikBag) => <React.Fragment key="buttons">
		{formButtons
			.map((button) => {
				const props = {
					onUploadFile: (f) => onUploadFile(f, formikBag),
					setState: _setState,
					waveColor,
					isEditMessage: !!editMessage,
					chatId: chat.id,
					getPopupContainer: getActionPopupContainer,
					chat,
				};
				const wrapper = typeof button !== 'function' && chatFormButtonsWrappers[button]
					? chatFormButtonsWrappers[button] : (children) => <React.Fragment key={`${button}`}>{children}</React.Fragment>;

				const buttonRender = typeof button === 'function'
					? button
					: plugins[button] && plugins[button]?.formButton !== null
						? plugins[button]?.formButton
						: undefined;

				return !buttonRender ? null : wrapper ? wrapper(buttonRender(formikBag, props)) : buttonRender(formikBag, props);
			})
		}
	</React.Fragment>;

	const initValue = {
		text: editMessage?.text || '',
		id: editMessage?.id || -1,
		chatId: editMessage?.chatId || chat.id,
		files: editMessage?.files.map((f) => f.file) || [],
		attachments: [],
		bytes: [],
		chatMessageType: replyMessage ? ChatMessageType.ReplyMessage : ChatMessageType.Regular,
		innerChatMessageId: replyMessage?.id,
	} as NewMessage;

	return <ItemProvider<NewMessage>
		key={editMessage ? 'editMessage' : replyMessage ? 'replyMessage' : 'newMessage'}
		type={editMessage && requests.updateMessage ? requests.updateMessage : requests.chatMessage}
		id={editMessage?.id || -1}
		skipInitLoad
		item={initValue}
		loadRequest=""
		add={initValue}
		validationSchema={validationSchema}
		clearForSubmit={(values) => {
			const item = values;

			item.chatId = props.chat.id;
			item.attachments = item.files.map((f) => f.id);
			return item;
		}}
		onSaveRequestError={(e) => (errorHandlers?.onSaveMessageLoadError || message.error)(e)}
		readonly={false}
		transformAfterSave={() => ({
			text: '',
			id: -1,
			chatId: chat.id,
			files: [],
			attachments: [],
			bytes: [],
			chatMessageType: ChatMessageType.Regular,
			innerChatMessageId: undefined,
		})}
		saveRequest={editMessage && requests.updateMessage ? requests.updateMessage : requests.chatMessage}
	>
		<ItemEditor<NewMessage>
			formikProps={{
				enableReinitialize: true,
			}}
			afterSubmit={(item, res) => {
				_setState((prev) => ({
					...prev,
					recordAudioResult: undefined,
					recordVideoResult: undefined,
				}));
				props.afterSubmit && props.afterSubmit(res);
				if (editMessage) {
					setEdit(null);
				}
				if (replyMessage) {
					setReply && setReply(null);
				}
			}}
			beforeSubmit={(values, actions, submit) => {
				const {
					text, files, attachments, bytes,
				} = values;

				if (!(text.replace(/\s/g, '') || files?.length || attachments?.length || bytes?.length)) return;
				submit();
			}}
			formProps={{
				id: 'new-chat-message',
				className: 'chat-form-component',
			}}
			showMessages={false}
			edit={(formikBag, _, { loading }) => {
				const disabled = !formikBag.values.files.length
					&& !formikBag.values.text.replace(/\s/g, '').length
					&& formikBag.values.bytes.length <= 0;
				return <>
					{
						replyMessage
							? (
								<div className="form-reply-message">
									<i className="fa fa-reply" />
									<div className="form-reply-message__content">
										{
											plugins[replyMessage.chatMessageType]?.message?.render({
												message: replyMessage,
												contacts: [],
												withRemoteId: undefined,
												onImageClick: undefined,
												onMouseEnter: undefined,
												lastVideoCallId: undefined,
											})
										}
									</div>
									<button
										type="button"
										onClick={() => setReply && setReply(null)}
										className="form-reply-message__cancel btn btn-default btn-sm"
									>
										<i className="fa fa-times" />
									</button>
								</div>
							) : null
					}
					<FormikRef formikRef={form} formikBug={formikBag} />
					<FormikField
						fieldName="text"
						containerClassName={`chat-form-component__control-container ${messageControl
						&& 'chat-form-component__control-container_typing'}`}
						render={({ field, form }: FieldProps<string, NewMessage>) =>
							<MentionComponent
								style={{ width: '100%' }}
								onChange={(e) => form.setFieldValue(field.name, typeof e === 'string' ? e : e?.currentTarget?.value)}
								className="form-control"
								autoSize
								placeholder="Start typing your message"
								placement="top"
								value={field.value}
								onPaste={formSettings.allowPasteImages ? (e) => handlePaste(e, formikBag) : undefined}
								{...(messageControl && plugins[messageControl]?.formComponent
									? { options: mentionOptions } : {})}
							/>
						}
					/>
					<div className="chat-uploaded-files">
						{filesAfterButtons ? null : <Files
							formikBag={formikBag}
							setState={_setState}
							state={state}
							onImageClick={onImageClick}
							onRemoveUploadedFile={onRemoveUploadedFile}
						/>}
						<div className="chat-message-flex">
							{formSettings.underFormLabel && <span className="chat-message-item__label chat-message-item">
								{formSettings.underFormLabel}
							</span>}
							{!actionsInPopup ? getActions(formikBag)
								: (
									<Popover
										getPopupContainer={(node) => node.closest('.chat-message-flex') || document.body}
										trigger="click"
										content={getActions(formikBag)}
										placement="topRight"
									>
										<button type="button" className="btn btn-default ml10 btn-sm">
											<i className="fa fa-ellipsis-h" />
										</button>
									</Popover>
								)}
							{
								editMessage ? <Button
									className="btn btn-sm chat-message-item btn-danger"
									type="button"
									onClick={() => setEdit(null)}
								>
									Cancel
								</Button> : null
							}
							{buttonWrapper
								? buttonWrapper(
									<Button
										isLoading={loading}
										className={`btn btn-sm chat-message-item ${sendClassName}`}
										disabled={disabled}
									>
										Send
									</Button>,
									formikBag,
									disabled || loading,
								)
								: (
									<Button
										isLoading={loading}
										className={`btn btn-sm chat-message-item ${sendClassName}`}
										disabled={!formikBag.values.files.length
											&& !formikBag.values.text.replace(/\s/g, '').length
											&& formikBag.values.bytes.length <= 0}
									>
										Send
									</Button>
								)
							}
						</div>
					</div>
					{filesAfterButtons ? <Files
						formikBag={formikBag}
						setState={_setState}
						state={{ ...state, isLoading: loading }}
						onImageClick={onImageClick}
						onRemoveUploadedFile={onRemoveUploadedFile}
					/> : null}
				</>;
			}}
		/>
	</ItemProvider>;
};

export default ChatMessageForm;
