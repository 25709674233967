import * as React from 'react';

import ConfigProvider from 'antd/es/config-provider';
import loadable from '@loadable/component';
import { shallowEqual, useSelector } from 'react-redux';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import '@common/react/yupLocaleSettings';
import { LoadingProvider } from '@common/react/components/Core/LoadingProvider/LoadingProvider';
import ErrorBoundaryWithSentry from '@common/react/components/UI/ErrorBoundaryWithSentry/ErrorBoundaryWithSentry';
import { ChatSettingsProvider } from '@common/react/components/Chat/ChatSettingsProvider';
import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';
// import { ReactionsPlugin } from '@common/react/components/Chat/Reactions/ReactionsPlugin';
import { ChatPlugins } from '@common/react/components/Chat/Chat';
import Application from '@common/react/components/Core/Application/Application';
import NotificationGliderProvider from '@common/react/components/Chat/NotificationGliderProvider';
import Avatar from '@common/react/components/Forms/Avatar';
import MessageFlashingTime from '@common/react/components/Chat/MessageFlashingTime';
import { setLocalStorageValue } from '@common/react/utils/localStorage/localStorage';
import IdleTimer from '@common/react/components/Core/IdleTimer/IdleTimer';

import CartProviderWithUpdate from '@app/components/UI/CartProviderWithUpdate/CartProviderWithUpdate';
import HeaderSearch from '@app/components/UI/Header/HeaderSearch';
import PortalLoader from '@app/components/UI/PortalLoader';
import '@app/scss/components/chatMessageNotificationGlider.scss';
import { MenuStateProvider } from '@app/components/UI/Menu/MenuStateProvider';
import UserStateTracker from '@app/components/UI/UserStateTracker/UserStateTracker';
import { theme } from '@app/components/UI/ThemeConfig/ThemeConfig';
import RouteChangeTracker from '@app/components/Routes/RouteChangeTracker';
import PatientLocationsSetter from '@app/components/UI/PatientLocationsSetter/PatientLocationsSetter';
import { customReduxActions } from '@app/store/CustomReduxActions';
import { User, UserRole } from '@app/objects/User';
import { Init } from '@app/objects/Init';

import '@app/scss/style.scss';
import RouteWithFooter from '@app/components/Routes/RouteWithFooter';
import { ApplicationState } from '@app/store';

const params = { fallback: <Loader /> };

const ReactionNotificationHandler = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ReactionNotificationHandler" */
		'@common/react/components/Chat/ReactionNotificationHandler'
	)), { fallback: <></> });

const SignalRChats = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SignalRChats" */
		'@common/react/components/Chat/SignalRChats'
	)), { fallback: <></> });

const VideoChatModal = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "VideoChatModal" */
		'@common/react/components/UI/VideoChat/VideoChatModal'
	)), { fallback: <></> });

const VideoChatGlider = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "VideoChatGlider" */
		'@common/react/components/Chat/VideoChatGlider'
	)), { fallback: <></> });

const NotFound = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PageNotFound" */
		'@common/react/components/UI/PageNotFound/PageNotFound'
	)), params);

const renderAvatar = (state) => <Avatar
	type=""
	readonly
	className="avatar-container"
	thumbClassName=""
	showDownload={false}
	showOpenIcon={false}
	withModal={false}
	object={state}
	firstName={state.firstName ?? 'N'}
	lastName={state.lastName ?? 'N'}
/>;

const Layout: React.FC = ({ children }) => {
	const user = useSelector((state: ApplicationState) => state.login.user, shallowEqual);

	React.useEffect(() => {
		if (!user) {
			setLocalStorageValue('skipFillInvites', false);
		}
	}, [user]);

	return (
		<div className="app-container schema-gray-orange">
			<ErrorBoundaryWithSentry>
				<RouteChangeTracker id="G-B67V40VWKF" />
				<LoadingProvider loader={<PortalLoader />}>
					<RequestProvider
						errorComponents={{
							notFound: () => <RouteWithFooter component={NotFound} />,
						}}
					>
						<CartProviderWithUpdate>
							<MenuStateProvider>
								<UserStateTracker>
									<ConfigProvider theme={theme}>
										<Application<User, Init>
											initCustomHandler={customReduxActions}
											checkUserAccess={(user: User, roles: Array<UserRole>, props?: any) =>
												user && roles.includes(user.role)}
										>
											<HeaderSearch />
											<PatientLocationsSetter />
											<NotificationGliderProvider>
												<ChatSettingsProvider
													plugins={{
														// ChatPlugins.Reactions is not currently used. for this you need to add messageActions
														// [ChatPlugins.Reactions]: ReactionsPlugin
													}}
													// messageActions={[ ChatPlugins.Reactions ]}
												>
													{children}
													{user ? (<>
														<MessageFlashingTime />
														<ReactionNotificationHandler />
														<SignalRChats />
														<IdleTimer />
													</>) : null}
												</ChatSettingsProvider>
												{user ? (<>
													<VideoChatGlider renderAvatar={renderAvatar} />
													<VideoChatModal />
												</>) : null}
											</NotificationGliderProvider>
										</Application>
									</ConfigProvider>
								</UserStateTracker>
							</MenuStateProvider>
						</CartProviderWithUpdate>
					</RequestProvider>
				</LoadingProvider>
			</ErrorBoundaryWithSentry>
		</div>
	);
};

export default Layout;
