import * as React from 'react';

import { FormikProps } from 'formik';
import { MentionProps } from 'antd/lib/mentions';

import { List } from '@common/typescript/objects/List';
import { BaseUser, BaseUserWithAvatar } from '@common/react/objects/BaseUser';
import { Nullable } from '@common/typescript/objects/Nullable';
import { File, FileInterface } from '@common/typescript/objects/FileInterface';
import { ButtonsProps, ChatFormComponentState } from '@common/react/components/Chat/ChatMessageForm';
import { Notification } from '@common/typescript/objects/Notification';
import { ApplicationStateWithChats, ChatsActionCreators } from '@common/react/components/Chat/Store/Chats';

export enum ChatMessageType {
	Regular = 0,
	VoiceMessage = 1,
	Email = 2,
	PhoneCall = 3,
	VideoChat = 4,
	VideoMessage = 5,
	Sticker = 6,
	Giphy = 7,
	Tenor = 8,
	ForwardedMessage = 9,
	ReplyMessage = 10,
	UserAttached = 11,
	UserDetached = 12,
}

export enum ChatRoomType {
	Video,
	Audio
}

export enum ChatKind
{
	Personal = 0,
	Conference = 1,
	Group = 2,
	Help = 3,
	Sos = 4,
	Bot = 5,
}

export interface Chat<TUser extends BaseUserWithAvatar = BaseUserWithAvatar & {remoteId?: number}> {
	id: number;
	kind: ChatKind;
	time: number;
	name: string;
	contacts: Array<TUser>;
	contactsIds: Array<number>;
	messages: List<ChatMessage>;
	lastMessage: Nullable<ChatMessage>;
	unviewedMessagesCount: number;
	userId: number;
	archive?: boolean;
}

export enum ChatPlugins {
	Regular = 0,
	VoiceMessage = 1,
	Email = 2,
	PhoneCall = 3,
	VideoChat = 4,
	VideoMessage = 5,
	Sticker = 6,
	Giphy = 7,
	Tenor = 8,
	Forward = 9,
	Reply = 10,
	UserAttached = 11,
	UserDetached = 12,
	Edit = 16,
	Deleted = 17,
	VoiceCall = 18,
	Typing = 26,
	Files = 27,
	Emoji = 28,
	Mentions = 29,
	Reactions = 30,
	LinkPreview = 31,
	LinkPreviewGroup = 32,
	ColorBox = 33,
	Searching = 34,
	Archive = 35,
	AddChat = 36,
	OnlineFilter = 37,
	AllChats = 38,
	CompleteChat = 39,
	LeaveChat = 40,
	ChatNameEditor = 41,
	Copy = 42,
	ModalPlugin = 44,
	Template = 45,
	AdaptiveCards = 46,
	SendLater = 47,
}

export interface ChatPlugin {
	formButton?: (formikBag: FormikProps<NewMessage>, props: ChatFormButtonsProps) => React.ReactNode;
	formComponent?: (props: MentionProps) => React.ReactNode;
	messageControlWrapper?: ({ chat, render }) => React.ReactNode;
	listComponent?: (props) => React.ReactNode;
	additionalComponent?: (props) => React.ReactNode;
	message?: {
		render: ({
			message, contacts, withRemoteId, onMouseEnter, lastVideoCallId, onImageClick,
		}) => React.ReactNode;
		lastMessage?: ({ message, chat, userId }) => React.ReactNode;
		notification?: ({ message, withRemoteId, contacts }) => React.ReactNode;
	};
	formTag?: (formikBag: FormikProps<NewMessage>, { state, setState, waveColor }) => React.ReactNode;
	messagesHeaderAction?:
		({
			currentChat,
			user,
			leaveChat,
			history,
			patientId,
			state,
			completeChat,
			withRemoteId,
			actions,
			storageName,
		} : {
			currentChat,
			user,
			leaveChat,
			history,
			patientId,
			state,
			completeChat,
			withRemoteId,
			actions: ChatsActionCreators<BaseUserWithAvatar, ApplicationStateWithChats<BaseUserWithAvatar>>,
			storageName
		}) => React.ReactNode;
	chatMessageAction?: ({
		edit, remove, isEdit, message, update, fromUser, options, reply,
	}) => React.ReactNode;
	chatsListHeaderComponent?: ({
		filters, handleChange, selectChat, pageSettings, user,
	}) => React.ReactNode;
	onMessageListClick?: (e, chat: Chat) => void;
	messageAttachment?: (message) => React.ReactNode;
	messageAttachmentBefore?: (message) => React.ReactNode;
	notificationHandler?: (
		notification: Notification<BaseUser>,
		storeName: string,
		actions: ChatsActionCreators<BaseUserWithAvatar, ApplicationStateWithChats<BaseUserWithAvatar>>,
		options: any // plugin options
	) => void;
	sendButtonWrapper?: (button, formikBag, disabled) => React.ReactNode;
	options?: any;
}

export type ChatListHeaderSettingsType = Array<ChatPlugins |
	(({
		user, selectChat, handleChange, filters, pageSettings,
	}) => React.ReactNode)>;

type CustomComponent = (({
	currentChat, user, leaveChat, history, patientId, state, completeChat, withRemoteId, actions,
}) => React.ReactNode);

export type ChatMessagesHeaderSettingsType = Array<ChatPlugins | CustomComponent>;

export type ChatNameType = ({
	currentChat, user, withRemoteId, patientId,
}) => React.ReactNode;

export interface EmojiReaction {
	id: number;
	userId: number;
	user: BaseUserWithAvatar;
	objectId: number;
	object: ChatMessage;
	reaction: string;
	animate?: boolean;
}

export interface BaseChatMessage {
	id: number;
	text: string;
	chatId: number;
	files?: Array<File>;
	loading?: boolean;
}

export interface ChatMessage {
	id: number;
	text: string;
	chatId: number;
	chat?: Chat;
	userId: number;
	user?: BaseUserWithAvatar;
	time: number;
	sendingTime: number;
	messageViewers: Array<ChatMessageAccess>;
	viewed: boolean;
	viewLoading?: boolean;
	files: Array<File>;
	emojiReactions: Array<EmojiReaction>;
	chatMessageType: ChatMessageType;
	chatRoomType?: ChatRoomType;
	innerChatMessageId: Nullable<number>;
	innerChatMessage: Nullable<ChatMessage>;
	loading?: boolean;
}

export interface ChatMessageAccess {
	chatMessageObject?: ChatMessage;
	id: number;
	message: number;
	time: number;
	user?: BaseUserWithAvatar;
	userId: number;
	viewed: boolean;
}

export interface ChatUser {
	id: number;
	chatId: number;
	userId: number;
	user?: BaseUserWithAvatar;
	chat?: Chat;
}

export interface NewMessage {
	id: number;
	text: string;
	chatId: number;

	bytes: Array<number>;
	files: Array<FileInterface>;
	attachments: Array<number>;
	chatMessageType: ChatMessageType;
	innerChatMessageId?: number;
	sendingTime?: number;
}

export interface ChatFormButtonsProps extends ButtonsProps {
	chat: Chat;
	isEditMessage?: boolean;
	waveColor?: string;
	setState: React.Dispatch<React.SetStateAction<ChatFormComponentState>>;
	chatId?: number;
	getPopupContainer?: (node) => HTMLElement;
}

export type ChatFormButtonsWrappers = {
	[key in ChatPlugins]?: (children) => React.ReactNode;
};

export type ChatFormButtonsComponents = ChatPlugins | ((formikBag: FormikProps<NewMessage>, props: ChatFormButtonsProps) => React.ReactNode);

export enum ChatHeaderButtons {
	Voice,
	Video,
	Mail
}

export enum StickerType {
	Image,
	Giphy
}

export type ChatMessageActionsComponent = ChatPlugins
	| (({
		remove, update, edit, isEdit, message, fromUser, options,
	}) => React.ReactNode);

export type ChatOtherComponents = ChatPlugins | (({
	chat, defaultIdx, setImg, files, basePath,
}) => React.ReactNode);

export interface ChatStickerCollectionItem {
	image: string;
	description?: string;
	fullImage?: string;
}

export interface ChatStickerCollection {
	items: Array<ChatStickerCollectionItem>;
	image: string;
	description?: string;
	enable?: boolean;
}
