import React from 'react';

import { useIdleTimer } from 'react-idle-timer';

import { callWithConnectionOnce } from '@common/react/utils/configureSignarR';
import useRequest from '@common/react/hooks/useRequest';

interface ComponentProps {
	timeout?: number;
	callback?: (status: string) => void;
}

const IdleTimer: React.FC<ComponentProps> = ({
	timeout = 1000 * 60 * 5, // milliseconds
	callback,
}) => {
	const request = useRequest();
	const sendRequest = (value: boolean, connectionId: string | null | undefined) => {
		request<string>('sessionExtension', {
			awayFromKeyboard: value,
			signalrConnectionId: connectionId,
		}).then((res) => {
			callback && callback(res);
		}).catch((message) => {
			console.log(message);
		});
	};

	const handle = (value: boolean) => {
		callWithConnectionOnce((connectionId) => {
			sendRequest(value, connectionId);
		});
	};

	useIdleTimer({
		timeout,
		onIdle: (e) => handle(true),
		onActive: (e) => handle(false),
	});

	return null;
};

export default IdleTimer;
