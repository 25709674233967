import React from 'react';

import { RequestButton, RequestButtonProps } from '@common/react/components/UI/LoadingButton/LoadingButton';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

interface Props extends Omit<RequestButtonProps<any>, 'requestType' | 'requestProps' | 'children'> {
	onSuccess?: () => void;
	wrapper?: (children) => React.ReactNode;
	children?: any;
}

const TransmutationBack: React.FC<Props> = ({
	onSuccess,
	children = <i className="fa fa-arrow-left" title="Login back" />,
	title = 'Login back',
	wrapper = (children) => children,
	...props
}) => {
	const {
		getTransmutationFlag,
	} = useApplicationContext();
	const transmuted = getTransmutationFlag();
	return <>
		{transmuted && wrapper(<RequestButton
			onSuccess={() => {
				window.location.href = '/';
				onSuccess && onSuccess();
			}}
			onError={(err) => {
				console.log(err);
			}}
			{...props}
			title={title}
			requestType="transmutation"
			requestProps={{
				id: 0,
				transmutation: !transmuted,
			}}
		>
			{children}
		</RequestButton>)}
	</>;
};

export default TransmutationBack;
