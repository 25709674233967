import React from 'react';
import { useCart } from 'react-use-cart';

import Tag from 'antd/lib/tag';

import { getMoneyString } from '@commonTuna/react/components/Utils';
import ImageWithSkeleton from '@commonTuna/react/components/UI/ImageWithSkeleton/ImageWithSkeleton';
import { numberWithComma } from '@commonTuna/react/utils/NumberWithComma/NumberWithComma';

import '@app/scss/components/shoppingCartInformation.scss';
import AddToCartButton from '@app/components/UI/AddToCartButton/AddToCartButton';
import EmptyCart from '@app/components/UI/EmptyCart/EmptyCart';
import { CartItem, transformStoredItemToCartItem } from '@app/objects/CartItem';
import useShoppingCart from '@app/hooks/useShoppingCart';
import { SpecialLocation } from '@app/components/Pages/Specials/SpecialCard';
import WithPathLink from '@app/components/UI/WithPathLink/WithPathLink';
import { getLocationName } from '@app/components/Utils';
import ProductTag from '@app/components/Pages/Products/ProductTag';
import Stars from '@app/components/UI/Stars/Stars';
import LocationInfo from '@app/components/Pages/DoctorPortal/LocationInfo';

import noImage from '@images/no-image.jpg';

interface Props {
	checkout?: React.ReactNode;
}

export const ShoppingCartItem: React.FC<{item: CartItem, forCheckout?: boolean}> = ({ item, forCheckout = true }) => {
	const { removeCartItem } = useShoppingCart();
	const locationRate = (<div className="stars">
		<strong className="mr10">
			<Stars value={item.location?.averageReviewRate < 1 ? 1 : item.location?.averageReviewRate || 0} />
		</strong>
		{numberWithComma(item.location?.averageReviewRate || 0)}
		{' '}
		(
		{item.location?.totalReviewCount || 0}
		)
	</div>);

	const main = (<>
		<WithPathLink className="name" prefix="specials" path={item.path}>
			<span className="main-info__title" title={item.name}>{item.name}</span>
		</WithPathLink>
		{item.location && !forCheckout
			&& <div className="map-point">
				<i className="fa fa-map-marker" />
				<WithPathLink prefix="clinics" path={item.location.portalPathEn}>
					<SpecialLocation text={getLocationName(item.location as any, false, true) as string} />
				</WithPathLink>
				{locationRate}
			</div>
		}
		{item.procedures?.length ? <div className="shopping-cart-information__tags">
			<label>Procedures:</label>
			{item.procedures.map((item) =>
				<Tag color="blue" key={item.id} style={{ fontSize: '16px' }}>
					{item.procedure.name}
				</Tag>)}
		</div> : null}
		{item.products?.length ? <div className="shopping-cart-information__tags">
			<label>Products:</label>
			{item.products.map((item) => item.product
				&& <ProductTag key={item.id} product={item.product} />)}
		</div> : null}
		{/* {forCheckout ? <>
			<span className="special-price mr10">{getMoneyString(item.price)}</span>
			<span className="special-original-price">{getMoneyString(item.originalPrice)}</span>
		</> : null} */}
	</>);

	return <div className="shopping-cart-information__item">
		<button type="button" className="cross" onClick={() => removeCartItem(item.id)} />
		<WithPathLink prefix="specials" path={item.path}>
			<ImageWithSkeleton src={item.originalAvatar ? `/remote/${item.originalAvatar}` : noImage} alt={item.name} />
		</WithPathLink>
		<span className="main-info">
			<div className="main-info__left-side">
				{forCheckout && item.location ? <>
					<div className="main-info__left-side__item">
						{main}
					</div>
					<div className="main-info__left-side__item">
						<div className="location-tab">
							{locationRate}
							<div className="map-point">
								<i className="fa fa-map-marker" />
								<WithPathLink prefix="clinics" path={item.location.portalPathEn}>
									<SpecialLocation text={getLocationName(item.location as any, false, true) as string} />
								</WithPathLink>
							</div>
							<div className="row card-panel location">
								<LocationInfo
									item={item.location}
									showRate={false}
								/>
							</div>
						</div>
					</div>
				</> : main}
			</div>
			<div className="main-info__right-side">
				<AddToCartButton special={item} withTotal={false} />
				<div className="item-total">
					<span className="special-price mr10">{getMoneyString(item.price * (item.quantity || 1))}</span>
					<span className="special-original-price">{getMoneyString(item.originalPrice * (item.quantity || 1))}</span>
				</div>
			</div>
		</span>
	</div>;
};

const ShoppingCartInformation: React.FC<Props> = ({ checkout }) => {
	const { items, cartTotal, isEmpty } = useCart();
	const cartItems = items.map(transformStoredItemToCartItem);
	const cartTotalOriginal = cartItems.reduce((acc, item) => acc + (item.originalPrice * (item.quantity || 1)), 0);

	return <div className="shopping-cart-information">
		{isEmpty ? <EmptyCart />
			: <div className="shopping-cart-information__container clearfix">
				<ul className="cart-item-list">
					{cartItems.map((item) =>
						<li key={item.id}>
							<ShoppingCartItem key={item.id} item={item} />
						</li>)}
				</ul>
				<div className="shopping-cart-information__bottom">
					<div className="total">
						Total:
						{' '}
						{getMoneyString(cartTotal)}
						<span className="special-original-price">
							{getMoneyString(cartTotalOriginal)}
						</span>
					</div>
				</div>
				{checkout}
			</div>
		}
	</div>;
};

export default ShoppingCartInformation;
