import * as React from 'react';

import Modal from 'antd/lib/modal';
import Tag from 'antd/lib/tag';

import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';
import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import { ItemEditorWrapper } from '@common/react/components/Core/ItemEditor/ItemEditorWrapper';

import ImageWithSkeleton from '@commonTuna/react/components/UI/ImageWithSkeleton/ImageWithSkeleton';
import { getMoneyString } from '@commonTuna/react/components/Utils';

import '@app/scss/components/specialModal.scss';
import '@app/scss/components/specialProductInfo.scss';
import PreviewImageLoader from '@app/components/UI/SpecialImageLoader/PreviewImageLoader';
import AddToCartButton from '@app/components/UI/AddToCartButton/AddToCartButton';
import WithPathLink from '@app/components/UI/WithPathLink/WithPathLink';
import { getLocationName } from '@app/components/Utils';
import ProductTag from '@app/components/Pages/Products/ProductTag';
import BuyNow from '@app/components/Pages/Specials/BuyNow';
import { Special } from '@app/objects/Special';
import { transformSpecialToCartItem } from '@app/objects/CartItem';

import noImage from '@images/no-image.jpg';

interface Props {
	id: number;
	open: boolean;
	setOpen: (open) => void;
}

const SpecialModal: React.FC<Props> = ({ id, open, setOpen }) => {
	const [imgLoading, setImgLoading] = React.useState<boolean>(true);

	const handleClose = () => {
		setOpen(false);
	};

	return <Modal
		width="800px"
		centered
		styles={{
			body: { height: '600px' },
		}}
		open={open}
		className="special-modal"
		onOk={handleClose}
		onCancel={handleClose}
		footer={null}
		destroyOnClose
	>
		<ItemProvider<Special>
			id={id}
			type="special"
			loadRequest="getSpecials"
		>
			<ItemEditorWrapper<Special>
				backPath=""
				className="special-container"
				render={({ state: { item: special } }) => {
					const specialForStore = transformSpecialToCartItem(special);
					return <div className="special-container item-container">
						<div className="item-info">
							<div className="left-side">
								<div className="item-img">
									{imgLoading
										? <PreviewImageLoader />
										: null
									}
									<ImageWithSkeleton
										src={special.originalAvatar ? `/remote/${special.originalAvatar}` : noImage}
										alt={special.name}
										onLoad={() => setImgLoading(false)}
										hidden={imgLoading}
									/>
								</div>
							</div>
							<div className="right-side">
								<div className="right-side__main">
									{special.name && <h1>{special.name}</h1>}
									<div className="right-side__info">
										{special.location
											&& <p className="map-point">
												<i className="fa fa-map-marker" />
												<WithPathLink prefix="clinics" path={special.location.portalPathEn}>
													{getLocationName(special.location, false, true)}
												</WithPathLink>
											</p>
										}
										{special?.procedures?.length > 0 && <>
											<h3>
												Procedures
											</h3>
											<div>
												{special.procedures.map((item) =>
													<Tag color="blue" key={item.id} style={{ fontSize: '16px' }}>
														{item.procedure.name}
													</Tag>)}
											</div>
										</>}
										{special?.products?.length > 0 && <>
											<h3>
												Products
											</h3>
											<div>
												{special.products.map((item) => item.product
													&& <div key={item.id}>
														<ProductTag product={item.product} />
													</div>)}
											</div>
										</>}
										{special.description && <p className="special-description custom-scroll">{special.description}</p>}
									</div>
									<div className="special-price__container">
										<span className="special-price mr10">{getMoneyString(special.price)}</span>
										<span className="special-original-price">{getMoneyString(special.originalPrice)}</span>
									</div>
									<div className="special-add-btn" key={special.id}>
										<AddToCartButton special={special} />
									</div>
								</div>
								<div className="right-side__details-btn">
									<BuyNow className="pull-left mr10" specialForStore={specialForStore} />
									<LinkWithPrevLocation to={`/specials/${special.path}`} className="">Show more details</LinkWithPrevLocation>
								</div>
							</div>
						</div>
					</div>;
				}}
			/>
		</ItemProvider>
	</Modal>;
};

export default SpecialModal;
