import * as React from 'react';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';

interface OwnProps {
	render?: (logout: (event: React.MouseEvent) => void) => JSX.Element;
	clearState?: boolean;
}

const Logout: React.FC<OwnProps> = ({
	render,
	clearState,
}) => {
	const { logout } = useApplicationContext();

	const logoutHandler = (event: React.MouseEvent) => {
		event.preventDefault();
		logout(clearState);
	};

	return render ? render(logoutHandler) : <a onClick={logoutHandler} className="logout-component">Logout</a>;
};

export default Logout;
