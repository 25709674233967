import React from 'react';

const useServerEffect = (callback) => {
	React.useMemo(() => {
		if (typeof window === 'undefined') {
			callback();
		}
	}, []);
};

export default useServerEffect;
