import * as React from 'react';

import moment, { Moment } from 'moment';

import { Location } from '@app/objects/Location';
import { LocationPortal } from '@app/objects/CompanyPortal';

interface Named {
	firstName: string;
	lastName: string;
}

interface NamedWithEmail extends Named {
	email: string;
}

export const getAppContainer = (): HTMLElement => document.body.querySelector('.app-container') || document.body;

export const showTime = (time, dots: boolean = true) => {
	if (!time) return '-';
	const [h, m] = time.split(':');
	if (+h === 0 && +m === 0) return `12:${m} a${dots ? '.' : ''}m${dots ? '.' : ''}`;
	return `${+h > 12 ? `${+h - 12 >= 10 ? '' : 0}${+h - 12}` : h}:${m} ${+h >= 12 && +h !== 24 ? 'p' : 'a'}${dots ? '.' : ''}m${dots ? '.' : ''}`;
};

export const getParentWidth = (parentSelector: string): number | undefined => {
	if (typeof document !== 'undefined' && typeof window !== 'undefined') {
		const parentEl = document.querySelector(parentSelector);
		if (!parentEl) {
			return;
		}

		const parentStyle = window.getComputedStyle(parentEl, null);
		if (!parentStyle.width) {
			return;
		}

		return parseInt(parentStyle.width, 10)
			- parseInt(parentStyle.paddingLeft || '', 10)
			- parseInt(parentStyle.paddingRight || '', 10);
	}
};

export const getUserNameOrUnnamedWithEmail = (item?: NamedWithEmail) => {
	if (item) {
		const name = (!item.lastName && !item.firstName) ? 'Unnamed' : `${item.lastName || ''} ${item.firstName || ''}`;
		return `${name}  ${item.email ? `(${item.email})` : ''}`;
	}
	return '';
};

export const timeSpanToMinutes = (timeSpan: string): number | null => {
	const timeArr = timeSpan.split(':');
	if (timeArr.length === 3) {
		const hours = +timeArr[0];
		const minutes = +timeArr[1];
		return hours ? hours * 60 + minutes : minutes;
	}
	return null;
};

export const getLocationName = (
	location: Location | LocationPortal,
	truncateName: boolean = true,
	withoutContainer?: boolean,
	fullCompanyName?: boolean,
) => {
	const companyName = fullCompanyName
		? `${location?.company?.name || ''}${
			location?.company?.portalShortName ? ` (${location?.company?.portalShortName}) ` : ''}`
		: location?.company?.portalShortName || location?.company?.name;
	const name = companyName ? `${truncateString(companyName, 25, truncateName)} - ${location.nameEn}` : `${location.nameEn}`;

	return withoutContainer ? name : <div title={`${companyName ? `${companyName} - ` : ''}${location.nameEn}`}>
		{name}
	</div>;
};

export const getLocationAddress = (location: Location) => {
	const arr = [location.addressEn, location.city, location.state, location.zip].filter((str) => str);
	return arr.filter((e) => e).join(', ');
};

const truncateString = (str: string, maxLength: number, truncateName: boolean) => {
	if (truncateName && str.length > maxLength) {
		return `${str.slice(0, maxLength - 3)}...`;
	}
	return str;
};

export const dateTimeFormatString = 'MM/DD/YYYY h:mm A';

export const dateToUtc = (date): Moment => {
	const offset = moment().utcOffset();
	return moment(date).subtract(offset, 'minutes');
};

export const dateToUtcString = (date, format): string => {
	const utc = dateToUtc(date);
	return moment(utc).format(format);
};
