import React from 'react';
import { useCart } from 'react-use-cart';
import { useLocation } from 'react-router-dom';

import Popover from 'antd/lib/popover';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import { getPopupContainer } from '@common/react/components/Utils/Utils';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

import { getMoneyString } from '@commonTuna/react/components/Utils';

import { ShoppingCartItem } from '@app/components/UI/ShoppingCartInformation/ShoppingCartInformation';
import { transformStoredItemToCartItem } from '@app/objects/CartItem';

interface Props {
	withoutPopover?: boolean;
}

const hidePaths = [
	'shopping-cart',
	'checkout',
	'ordering',
];

const ShoppingCartDropdown: React.FC<Props> = ({ withoutPopover }) => {
	const [isInitialized, setIsInitialized] = React.useState<boolean>(false);
	const [showAll, setShowAll] = React.useState(false);
	const location = useLocation();
	const { items, cartTotal, totalItems } = useCart();
	const { getUser } = useApplicationContext();
	const user = getUser();

	React.useEffect(() => {
		setIsInitialized(true);
	}, []);

	const totalCount = isInitialized ? totalItems : 0;

	React.useEffect(() => {
		if (totalCount === 0) {
			setShowAll(false);
		}
	}, [totalCount > 0]);

	const content = (<LinkWithPrevLocation to="/shopping-cart">
		<div className="shopping-cart__icon">
			<i className="fa fa-shopping-cart" title="Shopping Cart" />
			<div className="total-items pulse-animation" hidden={totalCount === 0}>
				{totalCount}
			</div>
		</div>
	</LinkWithPrevLocation>);

	if (withoutPopover || totalCount === 0) {
		return <div className="shopping-cart">{content}</div>;
	}
	const cartItems = items.map(transformStoredItemToCartItem);
	const cartTotalOriginal = cartItems.reduce((acc, item) => acc + (item.originalPrice * (item.quantity || 1)), 0);

	return <div className="shopping-cart">
		<Popover
			key={user?.id}
			trigger="click"
			open={totalCount > 0 && !(hidePaths.some((path) => location.pathname.includes(path)))}
			placement="bottom"
			getTooltipContainer={getPopupContainer}
			overlayClassName="shopping-cart__popover"
			content={
				<div>
					<div className="shopping-cart-information__container">
						<ul className="cart-item-list custom-scroll">
							{cartItems.slice(0, showAll ? items.length : 1).map((item) =>
								<li key={item.id}>
									<ShoppingCartItem forCheckout={false} key={item.id} item={item} />
								</li>)}
						</ul>
						<div className="shopping-cart-information__bottom">
							<div className="total">
								Total:
								{' '}
								{getMoneyString(cartTotal)}
								<span className="special-original-price">
									{getMoneyString(cartTotalOriginal)}
								</span>
							</div>
						</div>
						<div className="text-end">
							{items.length > 1 ? <button
								className="btn btn-default"
								type="button"
								onClick={() => setShowAll((prev) => !prev)}
							>
								{showAll ? '' : `+${items.length - 1} `}
								Show All
							</button> : null}
							<LinkWithPrevLocation to="/ordering" className="btn btn-primary">
								Check out
							</LinkWithPrevLocation>
						</div>
					</div>
				</div>
			}
		>
			{content}
		</Popover>
	</div>;
};

export default ShoppingCartDropdown;
