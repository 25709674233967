import 'raf/polyfill';

import 'core-js/features/array/from';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/set';
import 'core-js/features/map';
import 'core-js/features/weak-map';
import 'core-js/features/promise';

import * as Sentry from '@sentry/browser';

import { bootClient, renderApp } from '@common/react/loadable/boot-client';
import { updateReducers } from '@common/react/configureStore';

import { ApplicationState, reducers } from '@app/store';
import { User } from '@app/objects/User';
import { routes } from '@app/routes';

bootClient<User, ApplicationState>(routes, reducers);

console.log(`ENVIRONMENT: ${typeof ENVIRONMENT !== 'undefined' ? ENVIRONMENT : ''}`);

// Allow Hot Module Replacement
if (module.hot) {
	module.hot.accept('@app/routes', () => {
		renderApp((require('@app/routes') as any).routes);
	});
}

// Enable Webpack hot module replacement for reducers
if (module.hot) {
	module.hot.accept('@app/store', () => {
		const nextRootReducer = require('@app/store');
		updateReducers((nextRootReducer as any).reducers);
	});
}

if (typeof ENVIRONMENT !== 'undefined' && ENVIRONMENT === 'production') {
	Sentry.init({ dsn: 'https://c615bb76d98d45c4ba76c5d43dcd9685@o389532.ingest.sentry.io/5356380' });
}
